import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { sample, toString } from "lodash";

import useShopifyFunctions from "../utils/useShopifyFunctions";
import Layout from "../layout";

const AddToCart = ({ title, shopifyId, amount, cartUrl, setCheckoutState }) => {
  const { addItem, getCheckout } = useShopifyFunctions();

  const addToCartHandler = async (_shopifyId, _amount, _customAttributes) => {
    addItem({
      variantId: _shopifyId,
      quantity: _amount,
      customAttributes: _customAttributes,
    })
      .then(() => {
        getCheckout().then((res) => setCheckoutState(res));
        // return true
        // navigate(_cartUrl);
      })
      .catch((error) => {
        throw new Error(error);
      });
  };

  return (
    <button
      type="button"
      onClick={() => {
        addToCartHandler(shopifyId, amount, [
          {
            key: "pet_name",
            value: "Ruby",
          },
          {
            key: "font",
            value: "Slackey",
          },
          {
            key: "font_style",
            value: "Stacked",
          },
          {
            key: "icons",
            value: "",
          },
          {
            key: "justForTesting",
            value: toString(Math.floor(Math.random() * 10)),
          },
        ]);
      }}
    >
      + {title}
    </button>
  );
};

const AddAndSend = ({ title, shopifyId, amount }) => {
  const { addItem, getCheckout } = useShopifyFunctions();

  const addToCartHandler = async (_shopifyId, _amount, _customAttributes) => {
    addItem({
      variantId: _shopifyId,
      quantity: _amount,
      customAttributes: _customAttributes,
    })
      .then(() => {
        getCheckout().then((res) => {
          console.log(`checkout id: ${res.id}`);
          console.log(`number of line items: ${res.lineItems.length}`);
          if (window.parent) {
            window.parent.postMessage(res.id, "*");
          }
        });
      })
      .catch((error) => {
        throw new Error(error);
      });
  };

  return (
    <button
      className="py-4 px-8 bg-black text-white"
      type="button"
      onClick={() => {
        addToCartHandler(shopifyId, amount, [
          {
            key: "pet_name",
            value: "Ruby",
          },
          {
            key: "font",
            value: "Slackey",
          },
          {
            key: "font_style",
            value: "Stacked",
          },
          {
            key: "icons",
            value: "",
          },
          {
            key: "justForTesting",
            value: toString(Math.floor(Math.random() * 10)),
          },
        ]);
      }}
    >
      + {title}
    </button>
  );
};

function HomePage({ data, location }) {
  const { checkout, resetCart, shopifyCheckoutId } = useShopifyFunctions();
  const {
    shopifyId,
    title,
    // options,
    variants,
    description,
  } = data.shopifyProduct;
  const [checkoutState, setCheckoutState] = useState({});

  return (
    <Layout location={location}>
      <>
        <Helmet title="Stuntpuppy" />
        <h3>
          <span>{title}</span> {" - "} {shopifyId}
        </h3>
        <p>{description}</p>
        <AddToCart
          title={title}
          amount={1}
          shopifyId={variants[0].shopifyId}
          setCheckoutState={setCheckoutState}
        />
        <hr className="border-t border-solid border-black" />
        <h3>cart</h3>
        <ul>
          {checkout.loaded &&
            checkoutState.lineItems &&
            checkoutState.lineItems.map((lineItem) => (
              <li key={lineItem.id}>
                {lineItem.title} - {lineItem.variant.title} -{" "}
                {lineItem.quantity}
              </li>
            ))}
        </ul>
        <button
          type="button"
          onClick={() => {
            resetCart();
          }}
        >
          reset cart
        </button>
        <hr className="border-t border-solid border-black" />
        <span>Checkout ID:</span> {shopifyCheckoutId}
        <hr className="border-t border-solid border-black" />
        <h3 className="mt-8 text-xl">For EFLYN</h3>
        <p>
          creates a checkout and, adds Collarlab with customAttributes, and
          sends checkout id with postMessage (to window.parent).
        </p>
        <AddAndSend
          title={title}
          amount={1}
          shopifyId={variants[0].shopifyId}
        />
      </>
    </Layout>
  );
}

export default HomePage;

export const query = graphql`
  {
    shopifyProduct(shopifyId: {eq: "gid://shopify/Product/6573303758946"}) {
      shopifyId
      title
      options {
        name
        values
      }
      variants {
        title
        shopifyId
      }
      description
    }
  }
`;
